@charset "UTF-8";
/*
ユーティリティ系おまとめファイル
*/
/*
██████   █████  ███████ ███████
██   ██ ██   ██ ██      ██
██████  ███████ ███████ █████
██   ██ ██   ██      ██ ██
██████  ██   ██ ███████ ███████
*/
/*
██████  ██████  ███████  █████  ██   ██ ██████   ██████  ██ ███    ██ ████████
██   ██ ██   ██ ██      ██   ██ ██  ██  ██   ██ ██    ██ ██ ████   ██    ██
██████  ██████  █████   ███████ █████   ██████  ██    ██ ██ ██ ██  ██    ██
██   ██ ██   ██ ██      ██   ██ ██  ██  ██      ██    ██ ██ ██  ██ ██    ██
██████  ██   ██ ███████ ██   ██ ██   ██ ██       ██████  ██ ██   ████    ██
*/
/*
 ██████  ██████  ██       ██████  ██████
██      ██    ██ ██      ██    ██ ██   ██
██      ██    ██ ██      ██    ██ ██████
██      ██    ██ ██      ██    ██ ██   ██
 ██████  ██████  ███████  ██████  ██   ██
*/
/*
████████ ███████ ██   ██ ████████
   ██    ██       ██ ██     ██
   ██    █████     ███      ██
   ██    ██       ██ ██     ██
   ██    ███████ ██   ██    ██
*/
.c-topicspath {
  max-width: 1138px;
  width: 100%;
  margin: 0 auto;
  padding: 0;
  position: absolute;
  left: 0;
  bottom: 100px;
  z-index: 2; }
  @media screen and (max-width: 1168px) {
    .c-topicspath {
      padding: 0 15px; } }
  @media screen and (max-width: 768px) {
    .c-topicspath {
      bottom: 65px; } }
  @media screen and (max-width: 568px) {
    .c-topicspath {
      bottom: 35px; } }
  .c-topicspath li {
    display: inline-block;
    font-size: 12px;
    color: #888888; }
    @media screen and (max-width: 768px) {
      .c-topicspath li {
        font-size: 0.7em; } }
    .c-topicspath li:after {
      content: "\f105";
      font-family: "Font Awesome 5 Free";
      font-weight: 600;
      display: inline-block;
      margin-left: 8px; }
    .c-topicspath li:last-child:after {
      content: none; }
    .c-topicspath li > a {
      color: #888888; }

.l-lowerCaption {
  position: relative;
  width: 100%;
  height: 334px;
  background-image: url("/inc/image/common/bg_lowerCaption.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  overflow-x: hidden; }
  @media screen and (max-width: 768px) {
    .l-lowerCaption {
      height: 260px; } }
  @media screen and (max-width: 568px) {
    .l-lowerCaption {
      height: 190px; } }
  .l-lowerCaption__inner {
    max-width: 1138px;
    width: 100%;
    height: 100%;
    margin: 0 auto;
    position: relative; }
    @media screen and (max-width: 1168px) {
      .l-lowerCaption__inner {
        padding: 0 15px; } }
  .l-lowerCaption__title {
    font-size: 38px;
    font-weight: bold;
    color: #333;
    padding: 0;
    width: 100%;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    z-index: 2; }
    @media screen and (max-width: 1168px) {
      .l-lowerCaption__title {
        padding: 0 15px; } }
    @media screen and (max-width: 768px) {
      .l-lowerCaption__title {
        font-size: 32px; } }
    @media screen and (max-width: 568px) {
      .l-lowerCaption__title {
        font-size: 28px; } }
    .l-lowerCaption__title small {
      font-size: 18px;
      font-weight: bold;
      color: #82A408;
      display: block;
      letter-spacing: 0.06em;
      text-transform: uppercase; }
